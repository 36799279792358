@use "@ithaka/pharos/lib/styles/pharos";
@import "@ithaka/pharos/lib/styles/variables";
@import "@ithaka/pharos/lib/styles/variables.css";
@import "@ithaka/pharos/lib/styles/fonts.css";
@import "@ithaka/pharos/lib/styles/typography.scss";
@import "@glidejs/glide/src/assets/sass/glide.core";

.homepage-mobile-heading {
  display: none;
}

// this could probably use a second pass to clean up

#home-search-form {
    grid-column: span 9;
    position: relative;
    z-index: 10;

    &.overlay {
        margin-bottom: -1 * $pharos-spacing-1-x;
    }

    .button {
        width: 100%;
    }

    .advanced-link {
        padding-left: var(--pharos-spacing-1-x);
    }

    .home-search-container {
        color: $pharos-color-black;
        display: flex;
        align-items: center;
        border: 1px solid $pharos-color-marble-gray-50;
        border-radius: 2px;
        background-color: $pharos-color-white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.32);

        &:focus-within {
            outline: 2px solid var(--pharos-form-element-color-border-focus);
        }

        h2 {
            margin-top: 0;
            margin-bottom: 0.175rem;

            label {
                font-size: emCalc(26px);
                font-weight: 600;
                color: #000;
                text-align: left;
                margin-bottom: 0;
            }
        }

        .row {
            margin-bottom: 0.4375rem
        }

        #searchBox {
            margin-bottom: 0;
            align-items: stretch;
            display: flex;
            width: 100%;

            .home-search-form__input {
                border: 0;
                margin-bottom: 0;
                margin-left: $pharos-spacing-one-and-a-half-x;
                padding: 0;
                outline: none;

                transition: transform var(--pharos-transition-base) 0s;
                width: 100%;
                display: block;
                background-color: #fff;
                overflow: visible;
            }
        }

        .default-query {
            cursor: text;
            background-color: $pharos-color-ui-20;
        }

        .selected-query {
            background-color: $pharos-color-glacier-blue-80;
        }

        li:focus {
            outline: 2px solid $pharos-color-focus;
            outline-offset: 2px;
        }

        .search-option {
            margin: $pharos-spacing-1-x 0;
            padding: 0 $pharos-spacing-one-and-a-half-x;
            white-space: nowrap;

            &.icon {
                height: $pharos-spacing-one-and-a-half-x
            }
        }

        .separator {
            display: inline-block;
            position: relative;
            content: " ";
            height: 36px;
            width: 1px;
            background-color: $pharos-color-marble-gray-80;
        }
    }
}

.homepage-container {
    grid-template-areas:
        ". heading ."
        "search search search"
        "hero hero hero"
        ". content .";

    & .main-heading {
        @include pharos.layout-content-width("1-col");
        grid-area: heading;
        margin-top: var(--pharos-spacing-2-x) !important;
        margin-bottom: var(--pharos-spacing-2-x) !important;
    }

    .hero-slider {
        grid-area: hero;
        width: 100%;
        overflow: hidden;

        &__footer {
            margin-top: var(--pharos-spacing-three-quarters-x);
            margin-bottom: var(--pharos-spacing-three-quarters-x);
            
            &__caption {
                @include pharos.font-base(
                    $font-size: var(--pharos-font-size-small),
                    $line-height: var(--pharos-line-height-small)
                );

                grid-column: span 7;
            }

            &__pagination {
                justify-self: end;
                grid-column: 9 / span 4;
                display: flex;
                align-items: center;
                column-gap: var(--pharos-spacing-one-half-x);
      
                & [class$="__label"] {
                    @include pharos.font-base(
                        $font-size: var(--pharos-font-size-small),
                        $line-height: var(--pharos-line-height-small),
                        $font-weight: var(--pharos-font-weight-bold)
                    );
                }
            }
        }
    }

    .search-layout {
        grid-area: search;
        z-index: 10;

        .search-form-and-link {
            grid-column: span 10;
            margin-bottom: -5rem;

            @include pharos.until(medium) {
                grid-column: 1/-1;
            }
        }

        .scope-selector {
            margin-bottom: -1rem;
        }
    }

    .thematic-container {
        grid-column: 1 / -1;
        margin-top: var(--pharos-spacing-5-x);
        overflow: hidden;
        padding: var(--pharos-spacing-one-quarter-x);
        position: relative;

        .thematic-slider {
            padding: 0 var(--pharos-spacing-one-half-x);
        }

        .thematic-slider__controls {
            position: absolute;
            top: 0;
            left: var(--pharos-spacing-one-half-x);
            width: calc(100% - var(--pharos-spacing-1-x));
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
        }

        .thematic-slider__track {
            margin: 0 var(--pharos-spacing-2-x);
        }
    }

    .collection-showcase--browse {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: var(--pharos-spacing-3-x);

        & [class$="__copy"] {
            align-self: center;
        }

        & [class$="__images"] {
            display: grid;
            grid-template-columns: 1fr 2fr 3fr 3fr 3fr 1fr;
            grid-template-rows: repeat(6, auto);
            grid-gap: var(--pharos-spacing-three-quarters-x);

            @include pharos.until(large) {
                grid-gap: var(--pharos-spacing-one-half-x);
            }

            @include pharos.until(medium) {
                grid-template-columns: 1fr 5fr 4fr 1fr;
                grid-template-rows: repeat(5, auto);
            }

            img {
                display: inline-block;
                height: auto;
                max-width: 100%;
            }

            img:nth-child(1) {
                grid-column: 4 / 5;
                grid-row: 3 / 7;

                @include pharos.until(medium) {
                    display: none;
                }
            }

            img:nth-child(2) {
                grid-row: 4 / 6;
                grid-column: 3 / 4;

                @include pharos.until(medium) {
                    display: none;
                }
            }

            img:nth-child(3) {
                grid-row: 3 / 5;
                grid-column: 5 / 7;

                @include pharos.until(medium) {
                    grid-row: 3/4;
                    grid-column: 3/4;
                }
            }

            img:nth-child(4) {
                grid-column: 2 / 4;
                grid-row: 3 / 4;

                @include pharos.until(medium) {
                    grid-column: 2/3;
                    grid-row: 2/3;
                    align-self: end;
                }
            }

            img:nth-child(5) {
                grid-row: 2 / 3;
                grid-column: 1 / 4;
                align-self: end;

                @include pharos.until(medium) {
                    grid-row: 3/5;
                    grid-column: 1/3;
                    align-self: start;
                }
            }

            img:nth-child(6) {
                grid-column: 4 / 6;
                grid-row: 1 / 3;
                align-self: end;

                @include pharos.until(medium) {
                    grid-column: 3/5;
                }
            }
        }
    }

    .collection-showcase--broaden {

        & ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: var(--pharos-spacing-3-x);
            list-style: none;
        }
    }

    .collection-showcase--browse, .collection-showcase--broaden {
        margin-top: var(--pharos-spacing-5-x);
        grid-column: 1 / -1;

        &:last-child {
            margin-bottom: var(--pharos-spacing-5-x);
        }
    }
}

// keep in case we add lazy loading

@keyframes animateBackground {

    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.swiper-lazy, .thematic-lazy {
    opacity: 0;
    transition: opacity ease 300ms;
}

.swiper-lazy-loaded {
    opacity: 1;
}

.swiper-lazy-preloader, .thematic-lazy-preloader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, pharos.$pharos-color-marble-gray-base, pharos.$pharos-color-marble-gray-97, pharos.$pharos-color-marble-gray-base, pharos.$pharos-color-marble-gray-97);
    background-size: 300% 100%;
    animation: animateBackground 1.5s linear infinite;
}

.thematic-lazy-preloader {
    height: 14rem;
}

.glide__slide--hero img, .glide__slide--thematic img {
    display: block;
    object-position: bottom;
    width: 100%;
}

.glide__slide--hero img {
    object-fit: cover;
}

.glide-slide--thematic img {
    object-fit: contain;
}


@include pharos.until(max) {

    #home-search-form {
        grid-column: span 9;
    }
}

@include pharos.until(medium) {

    #headerMountPoint {
        margin-bottom: 0 !important;
    }
  
    #home-search-form {
        grid-column: span 8;

        &.overlay {
            margin-bottom: 1 * $pharos-spacing-1-x;
        }
    }

    .homepage-container {

        .collection-showcase--browse {
            grid-template-columns: 1fr;
        }

        .collection-showcase--broaden ul {
            padding-inline-start: 0;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .hero-slider__footer__caption {
        grid-column: span 5 !important;
    }

    .hero-slider__footer__pagination {
        grid-column: span 3 !important;
    }
}

@media screen and (max-width: 425px) {
    .homepage-mobile-heading {
        display: block !important;
    }

    .homepage-heading {
      display: none !important;
    }
    
    #home-search-form {
        grid-column: span 4;

        .home-search-container {

            #searchBox {
                
                .home-search-form__input {
                    margin-left: $pharos-spacing-one-half-x;
                }
            }

            .search-option {
                margin: $pharos-spacing-three-quarters-x 0;
                padding: 0 $pharos-spacing-three-quarters-x;
            }
        }
    }

    .homepage-container {

        .main-heading {
            margin-top: var(--pharos-spacing-1-x) !important;
        }

        .collection-showcase--browse, .collection-showcase--broaden, .thematic-container {
            margin-top: var(--pharos-spacing-3-x);
        }

        .collection-showcase--browse, .collection-showcase--broaden ul {
            grid-template-columns: 1fr;
            grid-gap: var(--pharos-spacing-2-x);
        }
    }

    .hero-slider__footer {

        &__pagination, &__caption {
            grid-column: 1/-1 !important;
            width: 100%;
        }
    }
}
